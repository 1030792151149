<div>
  <mat-toolbar
    role="banner"
    class="nav-bar not-print container grid mat-elevation-z4"
    #muyticoToolbar
    id="muyticoToolbar"
    appNoCtxMenu
  >
    <mat-toolbar-row *ngIf="isGhost | async as ghost" class="safearea">
      <div class="w-100 container content-center">
        <div class="w-85 w-xs-90">
          <div class="vertical-center">
            <button
              mat-icon-button
              aria-label="Toggle navigation menu"
              *ngIf="isSmallScreen"
              (click)="drawer.toggle()"
              class="hamburger-menu"
            >
              <mat-icon>menu</mat-icon>
            </button>
            <div
              class="img-container"
              title="home"
              role="link"
              [routerLink]="['/home']"
            >
              <img
                src="assets/icons/muyTico-logo.svg"
                alt="Muy Tico"
                loading="lazy"
                class="pointer logo-desktop logo no-click"
              />

              <img
                src="assets/icons/muytico 2.svg"
                alt="Muy Tico"
                loading="lazy"
                class="pointer logo-mobile logo no-click"
              />
            </div>

            <app-search
              class="w-100"
              [drawer]="drawer"
              [utilities]="utilities"
              *ngIf="!(mobileDevice | async)"
            ></app-search>

            <div class="profile-home">
              <button
                role="link"
                mat-icon-button
                [routerLink]="['/profile/login']"
                aria-label="Go to login"
                *ngIf="ghost === '1'; else userCart"
              >
                <img
                  src="assets/icons/user-icon.svg"
                  alt="muytico"
                  loading="lazy"
                  width="22"
                  height="22"
                  class="pointer"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-toolbar-row>

    <mat-toolbar-row *ngIf="mobileDevice | async">
      <app-search
        class="w-100"
        [drawer]="drawer"
        [utilities]="utilities"
      ></app-search>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container
    appNoCtxMenu
    [ngClass]="{
      'top-mobile': (mobileDevice | async),
      'top-desktop': !(mobileDevice | async)
    }"
  >
    <mat-sidenav
      class="scroll-contain"
      #drawer
      [mode]="(mobileDevice | async) ? 'over' : 'over'"
      id="drawer"
      [fixedInViewport]="true"
    >
      <mat-action-list
        tabindex="0"
        role="navigation"
        *ngIf="categories$ | async as categories"
      >
        <div *ngFor="let category of categories">
          <div>
            <mat-list-item (click)="searchProductTag(category); drawer.close()">
              <img
                class="icon"
                [src]="
                  category?.File?.Route_File ?? 'assets/fondos/img-category.svg'
                "
                [alt]="category?.Value || 'Categoría sin imagen'"
              />
              <h3 class="text-category" matLine>{{ category.Value }}</h3>
            </mat-list-item>
          </div>
        </div>
      </mat-action-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <main>
        <router-outlet> </router-outlet>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-menu #loggedUser="matMenu">
  <button
    mat-menu-item
    [routerLink]="['/cart']"
    (click)="installApp()"
    *ngIf="install | async"
  >
    <mat-icon>get_app</mat-icon>Instalar App
  </button>
  <div *ngIf="isGhost | async as ghost">
    <button
      role="menuitem"
      class="button"
      *ngIf="!translated"
      mat-menu-item
      (click)="translate(); $event.stopPropagation()"
      color="accent"
    >
      <mat-icon>g_translate</mat-icon>
      <span role="contentinfo"> Idioma </span>
    </button>
    <div id="google_translate_element" (click)="$event.stopPropagation()"></div>
    <button
      mat-menu-item
      [routerLink]="['/billing']"
      *ngIf="ghost === '0'"
      (click)="drawer.close()"
    >
      <mat-icon>description</mat-icon> Facturación electrónica
    </button>
    <button
      role="link"
      mat-menu-item
      [routerLink]="['/help']"
      (click)="drawer.close()"
    >
      <mat-icon>chat</mat-icon> Chat de ayuda
    </button>
    <button
      role="link"
      mat-menu-item
      [routerLink]="['/']"
      (click)="logout(); drawer.close()"
      *ngIf="ghost === '0'"
    >
      <mat-icon>exit_to_app</mat-icon> Cerrar sesión
    </button>
  </div>
</mat-menu>

<ng-template #noItem>
  <button
    mat-icon-button
    aria-label="Go to shopping cart"
    (click)="noItemsInCart()"
  >
    <mat-icon>shopping_cart</mat-icon>
  </button>
</ng-template>

<ng-template #userCart>
  <div class="profile-home">
    <button
      mat-icon-button
      (click)="openCart()"
      aria-label="Go to shopping cart"
      [matBadge]="quantity"
      matBadgePosition="below after"
      *ngIf="items | async as quantity; else noItem"
    >
      <mat-icon [ngClass]="{ 'select-icon': showCart }">shopping_cart</mat-icon>
    </button>
    <button
      mat-icon-button
      [routerLink]="['/profile']"
      aria-label="Go to my profile"
    >
      <img
        src="assets/icons/user-icon.svg"
        alt="muytico"
        loading="lazy"
        width="22"
        height="22"
        class="pointer"
      />
    </button>
  </div>
</ng-template>

<app-mini-cart
  *ngIf="showCart"
  class="mini-cart"
  (closeCart)="close()"
  (navigateToCart)="navigateToCart()"
></app-mini-cart>

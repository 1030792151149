<div class="cart-home" *ngIf="items | async as quantity">
  <mat-icon (click)="close()">arrow_back</mat-icon>
  <div class="info-cart-home">
    <p>
      <b>{{ quantity }}</b> artículos
    </p>

    <a (click)="clearCart()">Limpiar</a>
  </div>

  <div *ngIf="cart | async as data" class="center">
    <mat-card *ngFor="let item of data.Detail" class="size center">
      <div class="vertical-center">
        <img
          [src]="item.ProductFeature.Files | img : '2' : 1"
          [alt]="item.ProductFeature.Product.Name_Product"
        />

        <p class="name-product">
          {{ item.ProductFeature.Product.Name_Product }}<br />
          <span class="info-text">{{
            item.ProductFeature.Product.Description_Product
          }}</span>
          <span class="info-text">{{
            item.ProductFeature.Details | details
          }}</span>
        </p>
      </div>

      <div class="info-price-home w-100 vertical-center">
        <div *ngIf="loadingData | async as loadingData">
          <div class="input-products">
            <button
              mat-icon-button
              aria-label="Remove"
              (click)="reduceToCart(item.ProductFeature)"
              [disabled]="loadingData.loading"
              matPrefix
            >
              <mat-icon>remove</mat-icon>
            </button>
            <input
              type="number"
              [(ngModel)]="item.Quantity_BillDetail"
              class="content-center"
              (blur)="editQuantity($event, item)"
              (keyup.enter)="editQuantity($event, item)"
              [disabled]="loadingData.loading"
            />
            <button
              mat-icon-button
              aria-label="Add"
              (click)="addMore(item.ProductFeature)"
              [disabled]="loadingData.loading"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>

        <span
          [ngClass]="{
            discount: item.ProductFeature.ProductPrice.Discount
          }"
          class="product-price"
        >
          {{
            item.ProductFeature.ProductPrice
              | finalPrice
                : item.Quantity_BillDetail
                : item.SubTotal_BillDetail
                : false
              | async
          }}
        </span>
      </div>
    </mat-card>

    <div
      class="vertical-center total-button"
      *ngIf="total | async as priceDelivery"
    >
      <div class="hr"></div>
      <div class="subtotal">
        <p>Subtotal</p>
        <span>
          {{ priceDelivery.total + priceDelivery.tax | coinConversion | async }}
        </span>
      </div>

      <button class="cta-button w-100" (click)="navigateCart()">Pedir</button>
    </div>
  </div>
</div>

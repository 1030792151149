import { ɵgetAllInstancesOf as _getAllInstancesOf, ɵgetDefaultInstanceOf as _getDefaultInstanceOf, VERSION, ɵAngularFireSchedulers as _AngularFireSchedulers, ɵAppCheckInstances as _AppCheckInstances, ɵzoneWrap as _zoneWrap } from '@angular/fire';
import { timer, from } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { InjectionToken, Optional, NgModule, makeEnvironmentProviders, NgZone, Injector } from '@angular/core';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { AuthInstances } from '@angular/fire/auth';
import { registerVersion } from 'firebase/app';
import { fromTask as fromTask$1, percentage as percentage$1 } from 'rxfire/storage';
import { connectStorageEmulator as connectStorageEmulator$1, deleteObject as deleteObject$1, getBlob as getBlob$1, getBytes as getBytes$1, getDownloadURL as getDownloadURL$1, getMetadata as getMetadata$1, getStorage as getStorage$1, getStream as getStream$1, list as list$1, listAll as listAll$1, ref as ref$1, updateMetadata as updateMetadata$1, uploadBytes as uploadBytes$1, uploadBytesResumable as uploadBytesResumable$1, uploadString as uploadString$1 } from 'firebase/storage';
export * from 'firebase/storage';
class Storage {
  constructor(auth) {
    return auth;
  }
}
const STORAGE_PROVIDER_NAME = 'storage';
class StorageInstances {
  constructor() {
    return _getAllInstancesOf(STORAGE_PROVIDER_NAME);
  }
}
const storageInstance$ = timer(0, 300).pipe(concatMap(() => from(_getAllInstancesOf(STORAGE_PROVIDER_NAME))), distinct());
const PROVIDED_STORAGE_INSTANCES = new InjectionToken('angularfire2.storage-instances');
function defaultStorageInstanceFactory(provided, defaultApp) {
  const defaultStorage = _getDefaultInstanceOf(STORAGE_PROVIDER_NAME, provided, defaultApp);
  return defaultStorage && new Storage(defaultStorage);
}
function storageInstanceFactory(fn) {
  return (zone, injector) => {
    const storage = zone.runOutsideAngular(() => fn(injector));
    return new Storage(storage);
  };
}
const STORAGE_INSTANCES_PROVIDER = {
  provide: StorageInstances,
  deps: [[new Optional(), PROVIDED_STORAGE_INSTANCES]]
};
const DEFAULT_STORAGE_INSTANCE_PROVIDER = {
  provide: Storage,
  useFactory: defaultStorageInstanceFactory,
  deps: [[new Optional(), PROVIDED_STORAGE_INSTANCES], FirebaseApp]
};
class StorageModule {
  constructor() {
    registerVersion('angularfire', VERSION.full, 'gcs');
  }
  static ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "18.0.0",
    ngImport: i0,
    type: StorageModule,
    deps: [],
    target: i0.ɵɵFactoryTarget.NgModule
  });
  static ɵmod = i0.ɵɵngDeclareNgModule({
    minVersion: "14.0.0",
    version: "18.0.0",
    ngImport: i0,
    type: StorageModule
  });
  static ɵinj = i0.ɵɵngDeclareInjector({
    minVersion: "12.0.0",
    version: "18.0.0",
    ngImport: i0,
    type: StorageModule,
    providers: [DEFAULT_STORAGE_INSTANCE_PROVIDER, STORAGE_INSTANCES_PROVIDER]
  });
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "18.0.0",
  ngImport: i0,
  type: StorageModule,
  decorators: [{
    type: NgModule,
    args: [{
      providers: [DEFAULT_STORAGE_INSTANCE_PROVIDER, STORAGE_INSTANCES_PROVIDER]
    }]
  }],
  ctorParameters: () => []
});
function provideStorage(fn, ...deps) {
  registerVersion('angularfire', VERSION.full, 'gcs');
  return makeEnvironmentProviders([DEFAULT_STORAGE_INSTANCE_PROVIDER, STORAGE_INSTANCES_PROVIDER, {
    provide: PROVIDED_STORAGE_INSTANCES,
    useFactory: storageInstanceFactory(fn),
    multi: true,
    deps: [NgZone, Injector, _AngularFireSchedulers, FirebaseApps,
    // Defensively load Auth first, if provided
    [new Optional(), AuthInstances], [new Optional(), _AppCheckInstances], ...deps]
  }]);
}

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const fromTask = _zoneWrap(fromTask$1, true);
const percentage = _zoneWrap(percentage$1, true);

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const connectStorageEmulator = _zoneWrap(connectStorageEmulator$1, true);
const deleteObject = _zoneWrap(deleteObject$1, true);
const getBlob = _zoneWrap(getBlob$1, true);
const getBytes = _zoneWrap(getBytes$1, true);
const getDownloadURL = _zoneWrap(getDownloadURL$1, true);
const getMetadata = _zoneWrap(getMetadata$1, true);
const getStorage = _zoneWrap(getStorage$1, true);
const getStream = _zoneWrap(getStream$1, true);
const list = _zoneWrap(list$1, true);
const listAll = _zoneWrap(listAll$1, true);
const ref = _zoneWrap(ref$1, true);
const updateMetadata = _zoneWrap(updateMetadata$1, true);
const uploadBytes = _zoneWrap(uploadBytes$1, true);
const uploadBytesResumable = _zoneWrap(uploadBytesResumable$1, true);
const uploadString = _zoneWrap(uploadString$1, true);

/**
 * Generated bundle index. Do not edit.
 */

export { Storage, StorageInstances, StorageModule, connectStorageEmulator, deleteObject, fromTask, getBlob, getBytes, getDownloadURL, getMetadata, getStorage, getStream, list, listAll, percentage, provideStorage, ref, storageInstance$, updateMetadata, uploadBytes, uploadBytesResumable, uploadString };

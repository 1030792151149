import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "cart",
    loadChildren: () =>
      import("./pages/cart/cart.module").then((m) => m.CartModule),
  },
  {
    path: "my-carts",
    loadChildren: () =>
      import("./pages/my-carts/my-carts.module").then((m) => m.MyCartsModule),
  },
  {
    path: "search-result",
    loadChildren: () =>
      import("./pages/search-result/search-result.module").then(
        (m) => m.SearchResultModule
      ),
  },
  {
    path: "product/:id",
    loadChildren: () =>
      import("./pages/product-info/product-info.module").then(
        (m) => m.ProductInfoModule
      ),
  },
  {
    path: "terms",
    loadChildren: () =>
      import("./pages/terms-and-conditions/terms-and-conditions.module").then(
        (m) => m.TermsAndConditionsModule
      ),
  },
  {
    path: "help",
    loadChildren: () =>
      import("./pages/help/help.module").then((m) => m.HelpModule),
  },
  {
    path: "game",
    loadChildren: () =>
      import("./pages/game/game.module").then((m) => m.GameModule),
  },
  {
    path: "billing",
    loadChildren: () =>
      import("./pages/billing/billing.module").then((m) => m.BillingModule),
  },
  {
    path: "payment",
    loadChildren: () =>
      import("./pages/payment/payment.module").then((m) => m.PaymentModule),
  },
  { path: "**", redirectTo: "home" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
